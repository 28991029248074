export const maxWidthSizeValue = 100;

export const largestGridSize = 7;

export const assetGridBreakpoints = {
	xs: 12 / 2,
	sm: 12 / 3,
	md: 12 / 4,
	lg: 12 / 5,
	xl: 12 / largestGridSize,
};

export const storageGridBreakpoints = {
	xs: 12 / 2,
	sm: 12 / 3,
	md: 12 / 3,
	lg: 12 / 3,
	xl: 12 / 5,
};

export const profileGridBreakpoints = {
	xs: 12 / 2,
	sm: 12 / 3,
	md: 12 / 3,
	lg: 12 / 3,
	xl: 12 / 3,
};
