/* eslint-disable no-unused-vars */
export function fileToBase64(
	file: File,
	callback: (result?: {
		actual_file: File;
		filename: string;
		filetype: string;
		size: number;
		data: string | ArrayBuffer | null;
	}) => void,
) {
	const fileReader = new FileReader();
	fileReader.readAsDataURL(file);

	fileReader.onload = () => {
		const fileName = file.name.split('.')[0];
		const fileObj = {
			actual_file: file,
			filename: fileName,
			filetype: file.type,
			size: Math.round(file.size / 1000),
			data: fileReader.result,
		};
		callback(fileObj);
	};
}
