/* eslint-disable no-unused-vars */
import { type ReactElement } from 'react';
import { type UserProfile } from './User.model';

export type AssetId = string | number;

export type RequestFileData = {
	name: string;
	size: number;

	extension?: string;
	type?: string;
};

export type RequestFileUploadData = {
	files: RequestFileData | RequestFileData[];
	madeIn: string[];

	description?: string;
	metadata?: any;
};

export type RequestPublicAssetUploadData = RequestFileUploadData & {
	title: string;
	description?: string;
	category: string;
};

export enum AssetType {
	IMPORT = 'import',
	EXPORT = 'export',
	PROJECT = 'project',
}

export type PublicAsset = {
	// Database entries
	/** The project ID */
	id: number | string;
	/** The project title */
	title: string;
	/** The project description */
	description?: string;
	/** The project license */
	license?: string;
	madeIn?: Array<string>;
	/** The project creation date */
	createdAt?: string | Date;
	/** The project update date */
	updatedAt?: string | Date;
	/** The number of likes the project has */
	likes?: number;
	/** The number of bookmarks an asset has */
	bookmarks?: number;
	/** The number of views the project has */
	views?: number;
	/** The number of downloads the project has */
	downloads?: number;
	/** The number of times an asset has been remixed */
	remixes?: number;
	/** Trending weight value between 0-1 (higher means more trending) */
	weight?: number;
	/** Total number of times this project has been shared */
	shares?: number;
	/** Whether the project is starred/featured [true] or not [false, default] */
	isStarred?: boolean;
	/** Whether the project is visible to all [true, default] or not [false] */
	isPublic?: boolean;
	/** The project's tags */
	tags?: string[];
	/** The project's category [model - default, rig, animation, material] */
	category?: string;
	/** Metadata for projects */
	metadata?: any;

	// Cross references
	/** The owner of the project */
	user?: UserProfile;
	/** Synonym for user */
	owner?: UserProfile;
	/** The project files to display */
	files?: PublicAssetFile[];
	/** Another name for files */
	projectFiles?: PublicAssetFile[];
	/** The size of the project in bytes */
	size?: number;
	/** The number of times the project has been flagged */
	flags?: number;
	/** Same as id */
	objectID?: number | string;

	// Additional properties
	/** Whether the project is liked [true] or not [false, default] */
	liked?: boolean;
	/** Whether the project is bookmarked [true] or not [false, default] */
	bookmarked?: boolean;

	// Following is for continue projects:
	path?: string;

	// Following for empty assets/projects
	type?: string;

	// The source of the imported/exported private project
	source?: 'MPX GenAI' | 'MPX' | 'mpx' | 'pc' | 'sketchfab' | 'unity';

	// Algolia:
	/** The project's thumbnail URL from Algolia */
	thumbnailUrl?: string;

	// Export process:
	/** The project's export progress percentage */
	progress?: number;
	/** Used to determine the destination of an exported project. */
	destination?: 'community' | 'private';
	/** The project's tooltip */
	tooltip?: string | ReactElement;

	// Gen AI:
	/** This is an object containing the original Gen AI data before processing */
	originalData?: any;
};

export interface PublicAssetFile {
	/** Name of the project */
	name: string;
	/** The URL to download the file */
	downloadUrl: string;
	/** File size in bytes */
	size?: number;
	metadata?: AssetFileMetadata;
}

interface AssetFileMetadata {
	animations: string;
	has_animations: string;
	has_blendshapes: string;
	has_rig: string;
	has_skin: string;
	has_submeshes: string;
	has_texture_images: string;
	has_udims: string;
	has_uvs: string;
	has_vertex_colors: string;
	num_faces: string;
	num_meshes: string;
	num_rigs: string;
	num_unique_mats: string;
	num_verts: string;
	unique_mat_names: string;
	uv_max_u: string;
	uv_max_v: string;
	uv_min_u: string;
	uv_min_v: string;
}

export type PrivateAsset = {
	id: string;
	title: string;
	description?: string;
	source: string;
	archives: {
		glb: {
			size: number;
			filePath: string;
			bucket: string;
		};
	};
	metadata: AssetFileMetadata;
	createdAt: string;
	updatedAt: string;
	category: string;
	thumbnailUrl: string;
};

export type PrivateAssetDownloadUrl = {
	glb: {
		filePath: string;
		size: number;
		bucket: string;
		url: string;
	};
};

export type AssetSlots = {
	slotsProjects: number;
	slotsProjectsUsed: number;
	slotsPrivateImports: number;
	slotsPrivateImportsUsed: number;
};

export type LibraryUrlStates = {
	/** Active search query */
	q?: string;
	/** Active categories */
	c?: string | string[];
	/** Active sorting */
	s?: string;
	/** Whether to force certain params to stick */
	f?: boolean | string;
	/** Filter for a username */
	u?: string;
	/** Project being viewed (project ID) */
	id?: string | number;
};
