class ChargebeePlans {
	static GENAI_API = {
		OTP: {
			id: 'genai-api-otp2',
			name: 'OTP',
			originalPrice: 15.0,
			credits: 2500,
			isSubscription: false,
			secondaryMessage: undefined,
		},
	};

	static GENAI = {
		SMALL_MONTHLY: {
			id: 'genai-starter',
			name: 'Small Monthly',
			originalPrice: 15.0,
			discountedPrice: 10.99,
			credits: 750,
			isSubscription: true,
			highlight: '25% Off',
		},
		MEDIUM_MONTHLY: {
			id: 'genai-explorer',
			name: 'Medium Monthly',
			originalPrice: 30,
			discountedPrice: 19.99,
			credits: 1500,
			isSubscription: true,
			highlight: 'Most Popular - 25% Off',
		},
		LARGE_MONTHLY: {
			id: 'genai-lg',
			name: 'Large Monthly',
			originalPrice: 60,
			discountedPrice: 36.99,
			credits: 3000,
			isSubscription: true,
			highlight: '38% Off',
		},
		PAY_AS_YOU_GO: {
			id: 'genai-generation-pack',
			name: 'Large Monthly',
			originalPrice: 14.99,
			credits: 750,
			isSubscription: false,
			secondaryMessage: 'Switch to Monthly and Save',
		},
	};
}

export default ChargebeePlans;
