export interface UserBalance {
	created_at: any;
	currencies: UserBalanceCurrency[];
	updated_at: any;
}

export interface Currency {
	balance: number;
	currency: 'credits' | 'downloads' | 'exports' | 'generations' | 'api-credits';
}

export enum CurrencyType {
	Credits = 'credits',
	Downloads = 'downloads',
	Exports = 'exports',
	ApiCredits = 'api-credits',
	Generations = 'generations',
}

export interface UserBalanceCurrency {
	balance: number;
	currency: CurrencyType;
}
