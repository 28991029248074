export default class Env {
	static MPS_API_HOST = process.env.NEXT_PUBLIC_MPS_API_HOST;

	static CB = {
		PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_CB_PUBLISHABLE_KEY,
		SITE: process.env.NEXT_PUBLIC_MPS_CHARGEBEE_SITE ?? process.env.NEXT_PUBLIC_CB_SITE,
	};

	static PASSWORD_PROTECTED_SITE = process.env.PASSWORD_PROTECTED_SITE === 'true';

	static ANALYTICS_ENABLED = process.env.NEXT_PUBLIC_ANALYTICS_ENABLED === 'true';

	static SKETCHFAB_CLIENT_ID = process.env.NEXT_PUBLIC_SKETCHFAB_CLIENT_ID;

	static FASTCOMMENTS = {
		API_KEY: process.env.FASTCOMMENTS_API_KEY as string,
		CLIENT_ID: process.env.NEXT_PUBLIC_FASTCOMMENTS_CLIENT_ID,
	};

	static AUTH0 = {
		AUDIENCE: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
		BASE_URL: process.env.NEXT_PUBLIC_AUTH0_BASE_URL,
		CLIENT_ID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
		CLIENT_SECRET: process.env.AUTH0_CLIENT_SECRET,
		DOMAIN: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
		IN_APP_CLIENT_ID: process.env.NEXT_PUBLIC_AUTH0_IN_APP_CLIENT_ID,
	};

	static MPX_WEBSITE = process.env.NEXT_PUBLIC_MPX_WEBSITE;

	static MPX_GENAI_WEBSITE = '/generate';

	static SENTRY = {
		DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
		ENV: process.env.NEXT_PUBLIC_SENTRY_ENV,
	};

	static FIREBASE_CONFIG = process.env.NEXT_PUBLIC_FIREBASE_CONFIG
		? JSON.parse(process.env.NEXT_PUBLIC_FIREBASE_CONFIG)
		: null;

	static ALGOLIA = {
		APP_ID: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
		SEARCH_KEY: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY,
	};

	static ZENDESK = {
		apiKey: process.env.NEXT_PUBLIC_ZENDESK_API,
		email: process.env.NEXT_PUBLIC_ZENDESK_EMAIL,
		url: process.env.NEXT_PUBLIC_ZENDESK_URL,
	};

	static get isProduction() {
		return process.env.NEXT_PUBLIC_SENTRY_ENV === 'production';
	}

	static COOKIE_DOMAIN = process.env.NEXT_PUBLIC_SENTRY_ENV === 'production' ? '.masterpiecex.com' : undefined;
}
