export enum MeshType {
	ANIMAL = 'animal',
	OBJECT = 'object',
	HUMAN = 'human',
}

export enum GenerateMode {
	BASIC = 'Simple',
	ADVANCED = 'Custom',
}
