import Env from '@mpx-sdk/shared/configs/env';
import fbapp from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

class Firebase {
	get app() {
		return this.initialize();
	}

	get auth() {
		return this.initialize().auth();
	}

	get storage() {
		return this.initialize().storage();
	}

	initialize() {
		if (!fbapp.apps.length) {
			return fbapp.initializeApp(Env.FIREBASE_CONFIG);
		}

		return fbapp.app();
	}

	async signInWithCustomToken(token) {
		return this.auth.signInWithCustomToken(token);
	}

	getCurrentUser() {
		return this.auth.currentUser;
	}

	async logout() {
		return this.auth.signOut();
	}
}

const firebase = new Firebase();
export default firebase;
