/**
 * Search through the data recursively for the query.
 * This recursive search will look through all string values in the data object
 * and return the object if the query is found within any of the string values.
 * @returns {object[]} The data that matches or contains the query.
 * @export
 */
export function deepSearch(
	/** The data to search through. */
	data: object[],
	/** The query to search for. This accepts a single string, or an array of strings for fuzzy searching */
	query: string | string[],
): object[] {
	return data.filter((obj) =>
		Object.keys(obj).some((key) => {
			// If the value is an object, search through it recursively
			if (typeof obj[key] === 'object' && obj[key] !== null) {
				return deepSearch([obj[key]], query).length > 0;
			}

			// If the value is a string, search through it
			if (typeof obj[key] === 'string') {
				/** Regular expression to search for the query */
				let regex;
				if (Array.isArray(query)) {
					regex = new RegExp(query.join('|'), 'gi');
				} else {
					regex = new RegExp(query, 'gi');
				}

				return obj[key].match(regex) ?? false;
			}

			return false;
		}),
	);
}
