import { inAppBrowserAtom, store } from '@mpx-sdk/shared/atoms';
import Env from '@mpx-sdk/shared/configs/env';
import algoliasearch from 'algoliasearch';

export const defaultSearchConfig = (
	/** Whether this is being called in the Oculus app or not [false, default] */
	inApp = false,
) => {
	const defaultCategory =
		inApp || store.get(inAppBrowserAtom) || (typeof window !== 'undefined' && window?.vuplex)
			? ['model']
			: ['animation', 'material', 'model', 'rig'];

	return {
		category: defaultCategory,
		facet: [],
		facetFilters: [],
		hitsPerPage: store.get(inAppBrowserAtom) ? 12 : 44,
		indexName: 'projects',
		query: '',
		ruleContexts: ['library'],
		sortByIndex: 'projects',
		tags: [],
		sortByOptions: [
			{ indexName: 'projects', label: 'Most Relevant' },
			{
				indexName: 'projects_alpha_asc',
				label: 'Alphabetical (A-Z)',
			},
			{
				indexName: 'projects_alpha_desc',
				label: 'Alphabetical (Z-A)',
			},
			{
				indexName: 'projects_recent_uploaded',
				label: 'Recently Uploaded',
			},
		],
	};
};

export const searchClient = algoliasearch(Env.ALGOLIA.APP_ID, Env.ALGOLIA.SEARCH_KEY);
