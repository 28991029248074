import { store, userAtom, userHistoryAtom } from '@mpx-sdk/shared/atoms';
import { MLTypes, databases } from '@mpx-sdk/shared/configs';
import { deepSearch } from '@mpx-sdk/shared/utils';
import { type PublicAsset, type UserProfile } from '@mpx-sdk/types';
import { type FirebaseApp, getApp, getApps, initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { type Unsubscribe, collection, getFirestore, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { isEmpty } from 'lodash';

/**
 * Contains all firestore listeners, so this can be used before logout to unsubscribe
 */
export const subscriptions: Unsubscribe[] = [];

/** GenAI Firebase config */
export const genaiConfig = process.env.NEXT_PUBLIC_GENAI_FIREBASE_CONFIG
	? JSON.parse(process.env.NEXT_PUBLIC_GENAI_FIREBASE_CONFIG)
	: null;

/** Firebase app for GenAI */
// eslint-disable-next-line import/no-mutable-exports
export let genaiFirebase: FirebaseApp;

/**
 * @export
 */
export function initGenAIFirebase() {
	if (getApps().length < 2) {
		genaiFirebase = genaiConfig ? initializeApp(genaiConfig, 'genai') : getApp();
	} else {
		genaiFirebase = genaiConfig ? getApp('genai') : getApp();
	}
}

export function matchBucketToPostgresDataFormat(data: any, user?: UserProfile): PublicAsset | null {
	if (!data || typeof data !== 'object') {
		return null;
	}

	const convertedData: any = {};
	const currentUser = store.get(userAtom);

	// User data:
	if (currentUser) {
		convertedData.user = currentUser;
	}

	// Object ID:
	if (data.id) {
		convertedData.id = data.id.toString();
		convertedData.objectID = data.id.toString();
	}

	// Title, description and other text fields:
	if (data.projectTitle) {
		convertedData.title = data.projectTitle;
	}

	// Made in:
	convertedData.madeIn = ['MPX GenAI'];

	// Gen AI metadata:
	if (data.data) {
		convertedData.metadata = {};
		convertedData.metadata.genAIData = {
			'Paint Does Not Look Like': data.data.promptNeg,
			Animation: data.data.movesLikePrompt,
			Shape: data.data.category,
			'Paint Looks Like': data.data.promptPos,
			initialPrompt: data.data.initialPrompt,
			status: data.status,
			progress: data.progress,
		};
	}

	// Time:
	if (data.createdAt && data.createdAt?.seconds && data.createdAt?.nanoseconds) {
		const createdAt = new Date(data.createdAt.seconds * 1000 + data.createdAt.nanoseconds / 1000000);

		if (createdAt) {
			convertedData.createdAt = createdAt;
		}
	}

	if (data.updatedAt) {
		const updateDate = new Date(data.updatedAt);

		if (updateDate) {
			convertedData.updatedAt = updateDate;
		}
	}

	// Files
	if (data.steps) {
		// Go through each step, find ones that end with a .glb, or an image file extension (jpg, png, etc)
		// Once found, grab its file name and add it to the files array in addition to the file URL
		convertedData.files = [];

		const fileExtensions = ['.glb', '.jpg', '.jpeg', '.png', '.gif'];

		data.steps.forEach((step: any) => {
			if (step.outputFile) {
				// If the file is a glb or image file, add it to the files array
				const fileExtension = step.outputFile.substring(step.outputFile.lastIndexOf('.'));

				if (fileExtensions.includes(fileExtension)) {
					convertedData.files.push({
						name: step.outputFile.substring(step.outputFile.lastIndexOf('/') + 1),
						downloadUrl: step.outputFile,
					});

					if (step.type === 'thumbnail') {
						convertedData.thumbnailUrl = step.outputFile;
					}
				}
			}
		});
	}

	// User data
	if (user) {
		convertedData.user = user;
	}

	// Category
	convertedData.category = 'model';

	// Indicate that this is a GenAI model
	convertedData.type = 'history';

	// Original data
	convertedData.originalData = data;

	return convertedData;
}

/**
 * Fetches the history data for the current user from the Firestore database. The results are stored in the `userHistoryAtom` atom in addition to being returned.
 * @export
 */
export async function getHistoryData(
	/** The ID of the user to fetch the history data for. If not provided, the current user's ID will be used. */
	userId?: UserProfile['id'] | UserProfile['auth0Id'],
	/** The function to run on the data before it is stored in the atom. */
	onUpdate?: (data: any) => any,
	/** The order to sort the data by. */
	order: 'asc' | 'desc' = 'desc',
): Promise<any[]> {
	return new Promise((resolve) => {
		onAuthStateChanged(getAuth(genaiFirebase), (fbUser) => {
			if (!fbUser) {
				return resolve([]);
			}

			/** Current user */
			const currentUser = store.get(userAtom);
			/** Current user's ID */
			const user = userId ?? currentUser?.id;

			// If the user is logged in, fetch their history data
			if (user) {
				const q = query(
					collection(getFirestore(genaiFirebase), databases.mlRequest),
					where('userId', '==', user),
					where('type', '==', MLTypes.MESH_TO_ANIMATION),
					orderBy('createdAt', order),
				);

				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const unsubscribe = onSnapshot(q, (snapshot) => {
					/** History data */
					const data = snapshot.docs?.map((doc) => {
						const data = doc.data();

						return { ...data, id: doc.id };
					});

					if (onUpdate) {
						onUpdate(data || []);
					} else {
						// Store the data in the atom
						store.set(userHistoryAtom, data || []);
					}

					// Return the data
					resolve(data || []);
				});

				subscriptions.push(unsubscribe);
			}
		});
	});
}

/** Filters the history data based on the current filter and query. */
export default function customHistoryFilter(
	/** The history data to filter. */
	data: any[],
	/** The current filter, including things like sort order */
	currentFilter: string | string[],
	/** The query to search for (optional). */
	query?: string | string[],
): any[] {
	/** The data to use */
	const dataToUse = isEmpty(query) ? data : deepSearch(data, query ?? '');

	return dataToUse.filter((item) => {
		if (currentFilter.includes('all') || currentFilter.length === 0) {
			return true; // Include all items if 'all' is present in the filter
		}

		const {
			status,
			downloaded,
			data: { meshType },
		} = item;

		if (currentFilter.includes('complete') && status === 'complete') {
			return true; // Include if 'complete' or 'processing' is present in the filter and item status matches
		}

		if (
			currentFilter.includes('processing') &&
			(status === 'processing' || status === 'pending' || status === 'dispatching')
		) {
			return true; // Include if 'complete' or 'processing' is present in the filter and item status matches
		}

		if (currentFilter.includes('humans') && meshType === 'human') {
			return true; // Include if 'human' is present in the filter and item meshType is 'human'
		}

		if (currentFilter.includes('animals') && meshType === 'animal') {
			return true; // Include if 'animal' is present in the filter and item meshType is 'animal'
		}

		if (currentFilter.includes('objects') && meshType === 'object') {
			return true; // Include if 'object' is present in the filter and item meshType is 'object'
		}

		if (currentFilter.includes('downloads') && downloaded !== undefined) {
			return true; // Include if 'object' is present in the filter and item meshType is 'object'
		}

		return false; // Exclude the item if none of the filter conditions are met
	});
}
