/**
 * Get the file extension from a file name
 *
 * @example
 * getFileExtension('image.png') // 'png'
 * getFileExtension('image') // undefined
 */
export function getFileExtension(name: string) {
	const regex = /(?:\.([^.]+))?$/;
	return regex.exec(name)?.[1].toLowerCase();
}
